import axios from "axios";

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

Api.interceptors.request.use(
  (config: any) => {
    let obj: any = {};
    const app: any = localStorage.getItem("teambldr-app");
    if (app) {
      obj = JSON.parse(app);
      if (obj !== null && "auth" in obj) {
        const token = obj.auth.token;
        if (token) {
          config.headers["Authorization"] = token;
        }
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Api;
