import Api from "@/services/api";

type User = {
  first_name: string;
  last_name: string;
};
type AuthState = {
  token: string | null;
  logged: boolean;
  user: User;
};

const state: AuthState = {
  token: null,
  logged: false,
  user: {
    first_name: "",
    last_name: "",
  },
};

const getters = {
  token: (state: AuthState) => state.token,
  logged: (state: AuthState) => state.logged,
  user: (state: AuthState) => state.user,
};

const actions = {
  login(
    { dispatch, commit }: any,
    obj: { username: string; password: string }
  ) {
    dispatch("set_loading", true, { root: true });
    return new Promise((resolve) => {
      const payload = new FormData();
      payload.append("username", obj.username);
      payload.append("password", obj.password);
      Api.post("/login/access-token", payload).then((response: any) => {
        if (response.status === 200) {
          commit("SET_LOGGED", true);
          commit("SET_TOKEN", response.data.access_token);
          dispatch("set_loading", false, { root: true });
          resolve(true);
        } else {
          dispatch("set_loading", false, { root: true });
          resolve(false);
        }
      });
    });
  },
};

const mutations = {
  SET_LOGGED: (state: { logged: any }, payload: any) =>
    (state.logged = payload),
  SET_TOKEN: (state: { token: any }, payload: any) => (state.token = payload),
  SET_USER: (state: { user: any }, payload: any) => (state.user = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
