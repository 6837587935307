<template>
  <div class="login-wrapper">
    <a-form-model
      class="login-form"
      layout="vertical"
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item
        ref="username"
        label="Username or Email"
        prop="username"
      >
        <a-input
          v-model="form.username"
          @blur="
            () => {
              $refs.username.onFieldBlur();
            }
          "
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-model-item>

      <a-form-model-item ref="password" label="Password" prop="password">
        <a-input
          type="password"
          v-model="form.password"
          @blur="
            () => {
              $refs.password.onFieldBlur();
            }
          "
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" @click="onSubmit" :disabled="loading">
          Create
        </a-button>
        <a-button style="margin-left: 10px" @click="resetForm">
          Reset
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Welcome",
  data() {
    return {
      other: "",
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "Please input username", trigger: "blur" },
        ],
        password: [
          { required: true, message: "Please input password", trigger: "blur" },
          { min: 8, message: "Length should be minimum of 8", trigger: "blur" },
        ],
        // region: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
        // date1: [{ required: true, message: 'Please pick a date', trigger: 'change' }],
        // type: [
        //   {
        //     type: 'array',
        //     required: true,
        //     message: 'Please select at least one activity type',
        //     trigger: 'change',
        //   },
        // ],
        // resource: [
        //   { required: true, message: 'Please select activity resource', trigger: 'change' },
        // ],
        // desc: [{ required: true, message: 'Please input activity form', trigger: 'blur' }],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("auth/login", {
              username: this.form.username,
              password: this.form.password,
            })
            .then((response) => {
              if (response) {
                this.$router.push("/dashboard");
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
  },
  computed: {
    ...mapState(["loading"]),
  },
};
</script>
<style scoped>
.login-wrapper {
  margin: auto;
  border: 1px solid lightgrey;
  width: 25%;
}
.login-form {
  padding: 1.25rem;
}
</style>
