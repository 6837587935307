import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  key: "teambldr-app",
  storage: window.localStorage,
});

import auth from "./auth.module";

Vue.use(Vuex);

type BaseState = {
  loading: boolean;
};

export default new Vuex.Store({
  state: {
    loading: false,
  },
  actions: {
    set_loading({ commit }, payload: boolean) {
      commit("SET_LOADING", payload);
    },
  },
  mutations: {
    SET_LOADING: (state: BaseState, payload) => (state.loading = payload),
  },

  plugins: [vuexLocal.plugin],
  modules: {
    auth,
  },
});
